import React from 'react';
import {animated, config, Spring} from 'react-spring';

// Import styling components
import styled from 'styled-components';

// Styled components
const Button = styled(animated.button)`
  background: var(--black);
  color: var(--white);
  border: 2px solid transparent;
  border-radius: 50%;
  bottom: 4%;
  box-shadow: var(--shadow);
  height: 40px;
  outline: none;
  position: fixed;
  right: 4%;
  width: 40px;
  z-index: 0 !important;

  &:hover { cursor: pointer; }
`;

const scrollUp = e => {
  e.preventDefault();
  window.scrollTo({top: 0, behavior: 'smooth'});
}

export default () => (
  <Spring from={{opacity: 0}} to={{opacity: 1}}>
    {({opacity}) =>
    <Button onClick={scrollUp} style={{opacity}} config={config.stiff}>
      <i className="fas fa-arrow-up"></i>
    </Button>}
  </Spring>
);
