import React from 'react';
import Layout from './../components/Layout';
import styled from 'styled-components';
import {Link} from 'gatsby';

// Styled components
const NotFoundContainer = styled.article`
	display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	margin: auto;
	max-width: var(--line-length);
`;

export default () => (
	<Layout pageTitle="Page Not Found!">
		<NotFoundContainer>
			<h1>Page Not Found!</h1>
			<p>Sorry! Looks like whatever you're looking for isn't here.</p>
			<p> Maybe head back to the <Link to="/" className="link">home page</Link> to find what you're looking for?</p>
		</NotFoundContainer>
	</Layout>
);