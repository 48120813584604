import React from 'react';
import {Link} from 'gatsby';
import {navLinks, socialLinks} from '../utils/navLinks';
import {Context} from './Context';
import {Spring, animated, config} from 'react-spring';

// import styling components
import styled from 'styled-components';

// Styled components
const MobileNavContainer = styled(animated.nav)`
  background: rgba(0, 0, 0, 0.92);
  bottom: 0;
  box-shadow: var(--shadow);
  display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 12;
`;

const MobileNavLinkList = styled.ul`
  list-style: none;
  margin: 1.75rem 0 0 0;
  padding: 0;
`;

const MobileNavLinkListItem = styled.li`
  margin: 0.5rem 0;
  padding: 0 1.25rem;
  text-align: left;
  width: 100%;

  a {
    color: var(--white);
    font-size: calc(21px + 0.25vw);
    text-decoration: none;
  }
  a.active {
    color: var(--yellow);
    font-weight: 700;
  }
`;

// Functional components
const MobileNav = props => (
  <Spring from={{opacity: 0, right: '100%'}} to={{opacity: 1, right: '33.3%'}} config={config.gentle} native>
    {({opacity, right}) =>
      <MobileNavContainer className={props.navExpanded ? '' : 'appear'} style={{opacity, right}}>
        <MobileNavLinkList>
          {props.navLinks.map((link, index) => {
            return (
              <MobileNavLinkListItem key={index}>
                <Link to={link.route} activeClassName="active">{link.link}</Link>
              </MobileNavLinkListItem>)
          })}
        </MobileNavLinkList>

        <MobileNavLinkList>
          {props.socialLinks.map((link, index) => {
            return(
              <MobileNavLinkListItem key={index}>
                <a href={link.url} target="_blank" rel="noopener noreferrer">{link.link}</a>
              </MobileNavLinkListItem>)
          })}
        </MobileNavLinkList>
      </MobileNavContainer>}
    </Spring>
);

export default () => (
  <Context.Consumer>
    {({navExpanded}) => <MobileNav navExpanded={navExpanded} navLinks={navLinks} socialLinks={socialLinks}/>}
  </Context.Consumer>
);
