import React, {Component} from 'react';

// Create context for navigation state
export const Context = React.createContext();

export default class ContextProvider extends Component {
	state = { 
		headerCollapsed: false, 
		navExpanded: false
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		if (window.scrollY > 0) this.setState({ headerCollapsed: true });
		else this.setState({ headerCollapsed: false });
	}

	toggleNav = () => {
		this.setState(prevState => ({ navExpanded: !prevState.navExpanded }));
	}

	render() {
		return(
			<Context.Provider value={{
				headerCollapsed: this.state.headerCollapsed,
				navExpanded: this.state.navExpanded,
				toggleNav: this.toggleNav}}>
				{this.props.children}
			</Context.Provider>
		);
	}
}