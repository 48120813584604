import React from 'react';

//Import styling components
import styled from 'styled-components';
import logo from './../assets/logo.svg';

// Styled Components
const AllRightsReserved = styled.p`
	font-size: 0.9rem;
	margin-bottom: 1.5rem;
	text-align: center;
	text-style: italic;
`;

const FooterContainer = styled.footer`
	background: var(--black);
	border-top: 3px solid var(--yellow);
	box-sizing: border-box;
	box-shadow: var(--shadow-up);
	color: var(--white);
	margin-top: 2rem;
	padding: 0.5rem 1rem;
	position: relative;
	width: 100%;
	z-index: 1;
`;

const FooterLogo = styled.img`
	display: block;
	margin: auto;
	max-width: 50px;
	width: 100%;
`;

const FooterLogoContainer = styled.figure`
	display: flex;
		align-items: center;
		justify-content: center;
	padding: 1.25rem 0 0 0;
`;

export default () => (
	<FooterContainer>
		<FooterLogoContainer>
			<FooterLogo src={logo} alt="Ubben Logo"/>
		</FooterLogoContainer>

		<AllRightsReserved>Brandon Ubben &copy; 2019 &mdash; All Rights Reserved</AllRightsReserved>
	</FooterContainer>
);