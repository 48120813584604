import React from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {Helmet} from 'react-helmet';
import {Spring, animated, config} from 'react-spring';

// Import partial components
import Header from './Header';
import Footer from './Footer';
import ContextProvider, {Context} from './Context';
import MobileNav from './MobileNav';
import ScrollUpButton from './ScrollUpButton';
import './css/aileron.css';

// Styled components
const GlobalStyle = createGlobalStyle`
  :root {
    --black: #001011;
    --blue: #3066BE;
    --dark-blue: #2A324B;
    --grey: #454B66;
    --yellow: #ffa737;
    --white: #f1f3f5;
    --trans-black: rgba(0, 0, 0, 0.15);
    --trans-white: rgba(255, 255, 255, 0.666);
    --border-trans-black: 1px solid var(--trans-black);
    --line-length: calc(44rem + 0.25vw);
    --shadow: 1px 3px 8px rgba(0, 0, 0, 0.15);
    --shadow-up: 0px -3px 8px rgba(0, 0, 0, 0.15);
    --sans-serif: 'Aileron Heavy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Droid Sans', 'Helvetica', Arial, sans-serif;
  }
  * { box-sizing: border-box; }
  body {
    background: var(--white);
    color: var(--black);
    font-family: var(--sans-serif);
    font-size: calc(16px + 0.25vw);
    font-weight: 300;
    margin: 0 auto;
    padding: 0;
  }
  h1 { font-size: calc(33px + 0.25vw); }
  h2 { font-size: calc(28px + 0.25vw); }
  h3 { font-size: calc(26px + 0.25vw); }
  h4 { font-size: calc(24px + 0.25vw); }
  h5 { font-size: calc(22px + 0.25vw); }
  h6 { font-size: calc(20px + 0.25vw); }
  h1, h2, h3, h4, h5, h6 {
    font-family: var(--sans-serif);
    line-height: 2.65rem;
  }
  .link {
    color: var(--blue);
    font-weight: inherit;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .hidden { display: none; }
    @media (max-width: 620px) {
      body { font-size: 1.1rem; }
    }
`;

const MainContainer = styled(animated.main)`
  margin: 116px auto 1rem auto;
  line-height: 1.85rem;
  max-width: 1280px;
  min-height: 100vh;
  padding: 0.5rem;
  width: 100%;

  &.blur { filter: blur(4px) grayscale(100%); }
`;

export default props => (
  <ContextProvider>
    <GlobalStyle/>
    <Helmet>
      <meta name="keywords" content={ props.metaKeywords ? props.metaKeywords : 'ubben, brandon ubben, fullstack developer, developer portfolio, web developer, ubben.co'} />
      <meta name="description" content={ props.metaDescription ? props.metaDescription : 'Personal outlet for Brandon Ubben, a web developer based out of Los Angeles, CA.' }/>

      <title>{props.pageTitle} | Ubben.co</title>
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.4.1/css/all.css" integrity="sha384-5sAR7xN1Nv6T6+dT2mhtzEpVJvfS3NScPQTrOxhwjIuvcA67KV2R5Jz6kr4abQsz" crossorigin="anonymous" />
      <html lang="en"/>
    </Helmet>
    <Context.Consumer>
      {({headerCollapsed, navExpanded}) =>
        <>
          <Header/>
          { navExpanded ? <MobileNav/> : '' }
          <Spring from={{opacity: 0}} to={{opacity: 1}} config={config.gentle} delay={500} native>
            {({opacity}) =>
              <MainContainer className={navExpanded ? 'blur' : ''} style={{opacity}}>
                {props.children}
              </MainContainer>}
            </Spring>
            <Footer/>
            { headerCollapsed ? <ScrollUpButton /> : '' }
          </>}
        </Context.Consumer>
      </ContextProvider>
)
