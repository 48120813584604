import React from 'react';
import {Link} from 'gatsby';
import {Context} from './Context';
import {navLinks} from '../utils/navLinks';
import {navigate} from 'gatsby'

// Import styling components
import styled from 'styled-components';
import logo from './../assets/logo.svg';

// Styled components
const HeaderContainer = styled.header`
  background: var(--black);
  border-bottom: 2px solid var(--yellow);
  box-shadow: var(--shadow);
  box-sizing: border-box;
  color: var(--white);
  left: 0;
  padding: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 11;


  &.collapsed { background: rgba(0, 0, 0, 0.75); }
  &.collapsed img { max-width: 55px; }

  @media (max-width: 620px) {
          &.collapsed img { max-width: 35px; }
  }
`;

const HeaderNavContainer = styled.section`
  display: flex;
    align-items: center;
    justify-content: space-between;
  width: 100%;
`;

const HeaderNavLogo = styled.img`
  display: block;
  max-width: 80px;
  outline: none;
  padding-left: 1rem;
  transition: all 500ms;
  width: 100%;

  &:hover { cursor: pointer; }

  @media (max-width: 620px) {
      max-width: 65px;
      padding-left: 0;
  }
`;

const MenuButton = styled.button`
  background: transparent;
  border-color: transparent;
  color: var(--white);
  display: none;
  float: right;
  outline: none;

  @media (max-width: 700px) {
        display: block;
  }
`;

const NavLink = styled(Link)`
  color: var(--white);
  font-size: calc(12px + 0.25vw);
  font-weight: 400;
  margin: 1rem 0.75rem;
  padding: 0.25rem 0;
  text-decoration: none;

  &.active {
    border-bottom: 2px solid var(--yellow);
    font-weight: 700;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

const NavLinkContainer = styled.nav`
  text-align: right;
`;

// Stateless functional components
const HeaderNavLinks = props => (
  <NavLinkContainer>
    {props.links.map(link => {
      return <NavLink to={link.route} key={link.link} activeClassName="active">{link.link}</NavLink>
    })}
    <MenuButton onClick={props.onClick}>
      {props.navExpanded ? <React.Fragment><i className="fas fa-times fa-2x"></i> <span className="hidden">Close Menu Button</span></React.Fragment> : <React.Fragment><i className="fas fa-bars fa-2x"></i> <span className="hidden">Open Menu Button</span></React.Fragment>}
    </MenuButton>
  </NavLinkContainer>
);

export default props => (
  <Context.Consumer>
    {({headerCollapsed, navExpanded, toggleNav}) =>
      <HeaderContainer className={headerCollapsed ? 'collapsed' : ''} id="primary-nav">
        <HeaderNavContainer>
          <HeaderNavLogo src={logo} alt="UBBEN" onClick={() => navigate('/')} role="link" tabIndex="0"/>
          <HeaderNavLinks links={navLinks} onClick={toggleNav} navExpanded={navExpanded}/>
        </HeaderNavContainer>
      </HeaderContainer>}
    </Context.Consumer>
);
