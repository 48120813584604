// Nav links
export const navLinks = [
  { link: 'Home', route: '/' },
  { link: 'About', route: '/about' },
  { link: 'Blog', route: '/blog' },
  { link: 'Contact', route: '/contact' },
  { link: 'Portfolio', route: '/portfolio' },
];

export const socialLinks = [
  { link: 'Instagram', url: 'https://instagram.com/ubben.b' },
  { link: 'CodePen', url: 'https://codepen.io/ubbenco' },
  { link: 'Facebook', url: 'https://facebook.com/ubbenco' },
  { link: 'LinkedIn', url: 'https://www.linkedin.com/in/brandonubben/' },
  { link: 'Github', url: 'https://www.github.com/b-ubben' },
  { link: 'Twitter', url: 'https://www.twitter.com/ubbenco' },
].reverse();
